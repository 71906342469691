<template>
<ul class="app-nav">
    <li v-for="item in tabList" :key="item.id" :class="{'active':selTab==item.id}" @click="selTabFun(item.id)" >
        <span>{{item.title}}</span>
        
    </li>
</ul>
<div style="margin: 10px;">
    <div v-for="item in allItemAry" :key="item.id" class="box-card" style="">
        <span class="span-txt">{{item.text}}</span>
        <span style='display: block;font-size:smaller;color: gray;margin-left: 10px;'>单位:{{item.unit}}</span>
        <div :id='item.id' style="width:100%;height:350px;"></div>
    </div>
</div>
<!-- 区域比较 -->
<div class="open-option-example" v-if='!isShowDiffer'>
    <i class="iconfont ec-icon-duibivs" style="font-size:24px;"  @click="isShowDiffer=!isShowDiffer"></i>
</div>
<el-drawer v-model="isShowDiffer" title="城市对比"  :size="320" custom-class="cus-drawer"  destroy-on-close>
    <SelRegonDiffer  />
</el-drawer>
</template>
<script>
import SelRegonDiffer from '@/components/SelRegionDiffer.vue'
import { ref } from '@vue/reactivity';
import { useRouter } from 'vue-router';
import {getItemList,getMultiRegionItemDataList,getHouseDataList} from '@/http/ecoApi.js'
import {addUserLog} from '@/http/basicsApi.js'
import { nextTick, onBeforeUnmount } from '@vue/runtime-core';
import * as echarts from 'echarts'
import {parseTimeOfCycle,getDateList,parseTime,colors} from '@/utils'
export default {
    components: {
        SelRegonDiffer
    },
    setup() {
        const router=useRouter();
        let echartsAry=ref([]);//echart对象
        let regionCodes=ref(router.currentRoute.value.query.code);
        let tabList=ref([{id:1,title:'GDP产业结构'},
        {id:2,title:'人民生活'},
        {id:3,title:'财政收支'},
        {id:4,title:'投资消费'},
        {id:5,title:'对外经贸'},
        {id:6,title:'金融能源'}]);
        let selTab=ref(1);//默认选中的tab为1
        let allItemAry=ref([]);//数组元素中有多个指标项为饼图
        let itemAry=ref([]);//数组元素中有多个指标项为饼图
        console.log(regionCodes.value);
        console.log(itemAry);
        let isShowDiffer=ref(false);//是否显示区域比较的区域选择框
        //选择tab项
        const selTabFun=(id)=>{
            selTab.value=id;
            echartsAry.value=[];
            if(id==1){
                itemAry.value=[{id:'gdp_1',  text:'地区GDP',itemId: '20002709',unit:'',showType:'bar'},
                {id:'gdp_2',text:'地区GDP指数',itemId:'20002725',unit:'',showType:'line'},
                {id:'gdp_3',text:'人均地区GDP',itemId:'20002724',unit:'',showType:'bar'},
                {id:'gdp_4',text:'人均地区GDP指数',itemId:'20008277',unit:'',showType:'line'},
                {id:'gdp_5',text:'产业结构',itemId:'20002710,20002711,20002712',unit:'',showType:'radar'}
                ]
                allItemAry.value=itemAry.value;
                getRegionItemDataFun();
            }
            else if(id==2){
                
                itemAry.value=[{id:'rmsh_1',text:'人均可支配收入',itemId: '20000920',unit:'',showType:'bar'},
                {id:'rmsh_2',text:'人均消费支出',itemId:'20000926',unit:'',showType:'bar'},
                {id:'rmsh_3',text:'年末常住人口',itemId:'20000998',unit:'',showType:'bar'},
                {id:'rmsh_4',text:'城镇化率',itemId:'20009796',unit:'',showType:'line'},
                ]
                let HouseItemAry=[{id:'rmsh_5',text:'住宅房价',itemId: '50000001',unit:'元/平方米',showType:'line_area'},
                {id:'rmsh_6',text:'住宅租金',itemId: '50000002',unit:'元/月/平方米',showType:'line_area'}];
                allItemAry.value=[...itemAry.value,...HouseItemAry];
                console.log(itemAry.value);
                getRegionItemDataFun();
              
                getHouseDataListFun(HouseItemAry);
            }
            else if(id==3){
                itemAry.value=[{id:'cz_1',text:'财政收入',itemId: '20000009',unit:'',showType:'bar'},
                {id:'cz_2',text:'财政支出',itemId:'20000034',unit:'',showType:'bar'},
                ]
                allItemAry.value=itemAry.value;
                getRegionItemDataFun();
            }
            else if(id==4){
                itemAry.value=[{id:'tzxf_1',text:'全社会固定资产投资增速',itemId: '20010507',unit:'',showType:'line_area'},
                {id:'tzxf_2',text:'房地产开发投资',itemId:'20002754',unit:'',showType:'line_area'},
                {id:'tzxf_3',text:'社会消费品零售总额',itemId:'20000848',unit:'',showType:'bar'}
                ]
                allItemAry.value=itemAry.value;
                getRegionItemDataFun();
            }
            else if(id==5){
                itemAry.value=[{id:'dwmy_1',text:'进出口总额',itemId: '20002696',unit:'',showType:'bar'},
                {id:'dwmy_2',text:'进口总额',itemId:'20002698',unit:'',showType:'bar'},
                {id:'dwmy_3',text:'出口总额',itemId:'20002697',unit:'',showType:'bar'}
                ]
                allItemAry.value=itemAry.value;
                getRegionItemDataFun();
            }
            else if(id==6){
                itemAry.value=[{id:'jrny_1',text:'全部金融机构贷款年末金额',itemId: '20008279',unit:'',showType:'bar'},
                {id:'jrny_2',text:'全部金融机构存款年末金额',itemId:'20008278',unit:'',showType:'bar'},
                {id:'jrny_3',text:'电力消费量',itemId:'20001559',unit:'',showType:'line_area'}
                ]
                allItemAry.value=itemAry.value;
                getRegionItemDataFun();
            }
        }
        //获取指标数据
        const getRegionItemDataFun=()=>{
            //获取指标项信息
            let itemIds= itemAry.value.map(t=>t.itemId).join(',');
            getItemList({itemId:itemIds,pageIndex:1,pageSize:1}).then(resItem=>{
            console.log(resItem);
                if(resItem.code==20000){
                    itemAry.value.forEach(t=>{
                        //指标项信息
                        let item=resItem.data.dataList.find(el=>el.itemId==t.itemId.split(',')[0]);//默认取第一个指标项
                        //设置单位
                        t.unit=item.itemUnit;
                        //折线、柱状的图表x轴为时间轴，需要时间段
                        if(t.showType=='line' || t.showType=='line_area' || t.showType=='bar'){
                            //根据更新周期获取时间列表
                            
                            let dateList=getDateList(item.itemCycle);
                            //console.log(dateList);
                            //获取数据
                            let params={
                                regionCode:regionCodes.value,
                                itemId:t.itemId,
                                dateType:1,
                                date:dateList[0]
                            };
                            //console.log(params);
                            getMultiRegionItemDataList(params).then(res=>{
                                console.log(res);
                                if(res.code==20000){
                                    let seriesData=[];
                                    regionCodes.value.split(',').forEach(r=>{
                                        let dtAry=res.data.filter(el=>el.itemId==t.itemId && el.regionCode==r);
                                        //补全时间列表上各个时间点的数据
                                        let dtList=[];
                                        dateList.forEach(d=>{
                                            let dt=dtAry.find(el=> new Date(el.date).getTime()==new Date(d).getTime());
                                            if(dt){
                                                dtList.push(dt.itemValue)
                                            }
                                            else{
                                                dtList.push(null);
                                            }
                                        })
                                        
                                        if(dtAry.length>0){
                                            if(t.showType=='line'){
                                                seriesData.push({
                                                    name:dtAry[0].regionName,
                                                    type: 'line',
                                                    smooth: true,
                                                    data:dtList,
                                                })
                                            }
                                            else if(t.showType=='line_area'){
                                                seriesData.push({
                                                    name:dtAry[0].regionName,
                                                    type: 'line',
                                                    smooth: true,
                                                    areaStyle: {},
                                                    data:dtList,
                                                })
                                            }
                                            else if(t.showType=='bar'){
                                                seriesData.push({
                                                    name:dtAry[0].regionName,
                                                    type: 'bar',
                                                    data:dtList,
                                                })
                                            }
                                        }
                                    })
                                    //图表
                                    nextTick(()=>{
                                        let myChart =echarts.init(document.getElementById(t.id));
                                        let option = {
                                            // backgroundColor:'transparent',
                                            color:colors,
                                            tooltip: {
                                                trigger: 'axis',
                                            },
                                            legend: {},
                                             grid: {
                                                top: 40,
                                                bottom: 30,
                                                containLabel:true
                                            },
                                            xAxis: {
                                                type: 'category',
                                                axisLabel: {
                                                    formatter: function (params) {
                                                        return parseTimeOfCycle(params,item.itemCycle)
                                                    }
                                                },
                                                axisPointer: {
                                                    label: {
                                                        formatter: function (params) {
                                                        return parseTimeOfCycle(params.value,item.itemCycle)
                                                        }
                                                    }
                                                },
                                                data: dateList
                                            },
                                            yAxis: {
                                                type: 'value'
                                            },
                                            series: seriesData
                                            };
                                        myChart.setOption(option);
                                        echartsAry.value.push(myChart);
                                    })
                                }
                            });
                        }
                        else if(t.showType=='radar'){
                            //获取数据
                            let params={
                                regionCode:regionCodes.value,
                                itemId:t.itemId,
                                dateType:2//时间点
                            };
                            console.log(params);
                            getMultiRegionItemDataList(params).then(res=>{
                                //console.log(res);
                                if(res.code==20000){
                                    let indicator=[];//指标信息
                                    let seriesData=[];
                                    let itemIds=t.itemId.split(',');
                                    let ii=0;
                                    regionCodes.value.split(',').forEach(r=>{
                                        let value=[];
                                        let name='';
                                        itemIds.forEach(i=>{
                                            //指标项信息
                                            if(ii==0){
                                                indicator.push({
                                                    text:resItem.data.dataList.find(d=>d.itemId==i).itemName
                                                })
                                            }
                                            //获取数据
                                            let dt= res.data.find(re=>re.regionCode==r && re.itemId==i);
                                            if(dt){
                                                value.push(dt.itemValue);
                                                name=dt.regionName;
                                            }
                                            else{
                                                value.push(null);
                                            }
                                        })
                                        if(name){
                                            seriesData.push({
                                                value: value,
                                                name: name,
                                                // areaStyle: {
                                                //     color: 'rgba(255, 228, 52, 0.6)'
                                                // }
                                            });
                                        }
                                        ii++;
                                    });
                                    //图表
                                    nextTick(()=>{
                                        let radarChart =echarts.init(document.getElementById(t.id));
                                        let option = {
                                            color: colors,
                                            // backgroundColor:'transparent',
                                            tooltip: {
                                                trigger: 'item'
                                            },
                                            legend: {},
                                            radar: [
                                                {
                                                indicator: indicator,
                                                center: ['50%', '50%'],
                                                // radius: 220,
                                                startAngle: 90,
                                                splitNumber: 4,
                                                shape: 'circle',
                                                axisName: {
                                                    formatter: '【{value}】',
                                                    color: '#428BD4'
                                                },
                                                // splitArea: {
                                                //     areaStyle: {
                                                //     color: ['#aaa', '#26C3BE'],
                                                //     //shadowColor: 'rgba(0, 0, 0, 0.2)',
                                                //     //shadowBlur: 10
                                                //     }
                                                // },
                                                // axisLine: {
                                                //     lineStyle: {
                                                //     color: 'rgba(211, 253, 250, 0.8)'
                                                //     }
                                                // },
                                                // splitLine: {
                                                //     lineStyle: {
                                                //     color: 'rgba(211, 253, 250, 0.8)'
                                                //     }
                                                // }
                                                }
                                            ],
                                            series: [
                                                {
                                                type: 'radar',
                                                emphasis: {
                                                    lineStyle: {
                                                    width: 4
                                                    }
                                                },
                                                data: seriesData
                                                }
                                            ]
                                        };
                                        radarChart.setOption(option);
                                        echartsAry.value.push(radarChart);
                                    });
                                }
                            });    
                        }
                    })
                }
            });
        }
        //获取房价数据列表，时间列表
        const getHouseDataListFun=async(objAry)=>{
            let dateList=getDateList(3);
            let date=new Date();
            dateList.push(date.getFullYear()+'-'+(date.getMonth()+1)+'-01');
            
            let params={
                pageIndex: 1,
                pageSize: 1,
                itemId:objAry.map(t=>t.itemId).join(','),
                regionCode:regionCodes.value,
                dateType:1,//时间段
                date:dateList[0]
            }
            //console.log(params)
            const res= await getHouseDataList(params);
            console.log(res);
            if( res.code==20000){
                objAry.forEach(t => {
                    let seriesData=[];
                    regionCodes.value.split(',').forEach(r=>{
                        let dtAry=res.data.dataList.filter(el=>el.regionCode==r);
                        //补全时间列表上各个时间点的数据
                        let dtList=[];
                        dateList.forEach(d=>{
                            let dt=dtAry.find(el=> new Date( parseTime(new Date(el.date),'{y}-{m}-{d}')).getTime()==new Date(d).getTime());
                            if(dt){
                                dtList.push(dt['p'+t.itemId])
                            }
                            else{
                                dtList.push(null);
                            }
                        })
                        if(dtAry.length>0){
                            if(t.showType=='line'){
                                seriesData.push({
                                    name:dtAry[0].regionName,
                                    type: 'line',
                                    smooth: true,
                                    data:dtList,
                                })
                            }
                            else if(t.showType=='line_area'){
                                seriesData.push({
                                    name:dtAry[0].regionName,
                                    type: 'line',
                                    smooth: true,
                                    areaStyle: {},
                                    data:dtList,
                                })
                            }
                            else if(t.showType=='bar'){
                                seriesData.push({
                                    name:dtAry[0].regionName,
                                    type: 'bar',
                                    data:dtList,
                                })
                            }
                        }
                    })
                    //图表
                    nextTick(()=>{
                        let myChart =echarts.init(document.getElementById(t.id));
                        let option = {
                            // backgroundColor:'transparent',
                            color:colors,
                            tooltip: {
                                trigger: 'axis',
                            },
                            legend: {},
                                grid: {
                                top: 40,
                                bottom: 30,
                                containLabel:true
                            },
                            xAxis: {
                                type: 'category',
                                axisLabel: {
                                    formatter: function (params) {
                                        return parseTimeOfCycle(params,3)
                                    }
                                },
                                axisPointer: {
                                    label: {
                                        formatter: function (params) {
                                        return parseTimeOfCycle(params.value,3)
                                        }
                                    }
                                },
                                data: dateList
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: seriesData
                            };
                        myChart.setOption(option);
                        echartsAry.value.push(myChart);
                    })
                });
            }  
        }
        //自适应
        let listener=()=> {
            echartsAry.value.forEach(element => {
                element.resize();
            });
        }
        window.addEventListener('resize', listener)//监听resize事件
        //执行
        selTabFun(selTab.value);
        //记录用户操作日志
        addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'加载',`{"regionCode":"${regionCodes.value}"}`);
        onBeforeUnmount(()=>{
            window.removeEventListener('resize',listener);
            echartsAry.value.forEach(element => {
              if (element) {
                element.clear(); //清空图表
                element.dispose(); //释放图表组件
                element = null;
              }
            }); 
        })
        return{
            regionCodes,
            tabList,
            selTab,
            selTabFun,
            allItemAry,
            isShowDiffer
        }
    },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
    .span-txt{
        line-height: 30px;
        font-weight: bold;
        padding-left: 10px;
        color: $specialtext-color;
    }
    .app-nav {
        background: #fff;
        margin-bottom: 10px;
        border-radius: 0 0 2px 2px;
        padding: 20px 20px 0 20px;
        border-bottom: 1px solid $bordercolor;
        li{
            display: inline-block;
            line-height: 25px;
            padding-bottom: 10px;
            margin-right: 30px;
            position: relative;
            cursor: pointer;
            font-size: 16px;
            letter-spacing: 1px;
        }
    }
    .app-nav  li.active span {
        color: $specialtext-color;
        font-weight: bold;
    }
    .app-nav li.active span::after {
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        display: inline-block;
        content: "";
        bottom: 0;
        background: $specialtext-color;
        z-index: 10;
    }
    .box-card{
        width:calc(33.3% - 20px);
        display: inline-block;
        margin: 10px;
        border: 1px solid $bordercolor;
        border-radius: 4px;
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 3px 0px;
    }
</style>